@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Inter, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    letter-spacing: -0.2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--extra-extra-light-grey);
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.posts-header {
    overflow: auto;
}

.posts-header h1 {
    float: left;
}

.btn-new-post {
    float: right;
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 18px;
    background: var(--accent);
    padding: 0.8rem;
    border-radius: 15px;
}

a.btn-new-post {
    text-decoration: none;
    color: var(--extra-extra-light-grey);
    font-weight: bold;
    transition: all 0.15s linear;
}

.btn-new-post:hover {
    background: var(--black);
}

:root {
    --accent: #1DA1F2;
    --black: #14171A;
    --dark-grey: #657786;
    --light-grey: #AAB8C2;
    --extra-light-grey: #E1E8ED;
    --extra-extra-light-grey: #F5F8FA;
    --link-embed-bg: #3e3e3e;
    --link-embed-hover: #565656;
    --error: #f21d37;
    --comment-submit: #7b35e4;
    --font-family: -apple-system, BlinkMacSystemFont, Inter, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.posts-container {
    margin-left: 20vw;
    margin-right: 20vw;
}

.posts-subheader h3 {
    font-weight: normal;
    margin: 0;
}

.posts-hint {
    margin: 0;
    margin-bottom: 1rem;
}

.posts-hint span {
    font-size: 0.85em;
}

.posts-hint span .tip-icon {
    color: #1DA1F2;
    color: var(--accent);
}

.horizontal-sep {
    border: 1px solid #E1E8ED;
    border: 1px solid var(--extra-light-grey);
    margin-bottom: 2rem;
}

.post-container {
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 16px;
    overflow: auto;
}

.post-username {
    font-weight: bold;
    color: #657786;
    color: var(--dark-grey);
}

.post-date {
    color: #657786;
    color: var(--dark-grey);
}

.sep {
    color: #AAB8C2;
    color: var(--light-grey);
}

h2 .post-link {
    text-decoration: none;
    color: #1DA1F2;
    color: var(--accent);
    font-weight: 900;
    transition: color 0.15s linear;
}

.view-full {
    text-decoration: underline;
    color: #657786;
    color: var(--dark-grey);
    transition: color 0.15s linear;
}

.view-full:hover {
    color: #14171A;
    color: var(--black);
}

.post-link:hover {
    color: #657786;
    color: var(--dark-grey);
}

.post-container .alignleft {
    float: left;
}

.post-container .alignright {
    float: right;
}

.post-footer {
    clear: both;
}

.post-footer-fa {
    margin-left: 10px;
}

.reactions {
    margin-top: 5px;
    margin-right: 1vw;
    background-color: #fff;
    padding: 10px;
    border-radius: 25px;
    box-shadow: 1px 3px 14px rgba(0,0,0,0.29) inset;
    color: #657786;
    color: var(--dark-grey);
}

.reactions a {
    text-decoration: none;
    transition: all 0.15s linear;
}

.reactions a:hover {
    filter: brightness(122%);
}

.comments {
    margin-right: 10px;
}

.link-embed-container {
    padding: 10px;
    background: #14171A;
    background: var(--black);
    width: -webkit-max-content;
    width: max-content;
    border-radius: 15px;
    transition: all 0.15s linear;
}

.link-embed-container:hover {
    background: #3e3e3e;
    background: var(--link-embed-bg);
    cursor: pointer;
}

.link-embed {
    text-decoration: none;
    color: #F5F8FA;
    color: var(--extra-extra-light-grey);
    font-size: 0.9em;
    font-weight: bold;
}

.link-url {
    transition: all 0.15s linear;
}

.link-url:hover {
    color: #1DA1F2;
    color: var(--accent);
    text-decoration: underline;
}

.link-hint {
    font-size: 0.8em;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.3ch;
}

.btn-reaction {
    border: none;
    background: transparent;
    font-size: 1rem;
    padding: 0;
    transition: all 0.15s linear;
}

.btn-reaction:hover {
    filter: brightness(122%);
    cursor: pointer;
}

.post-page-container {
    margin-left: 20vw;
    margin-right: 20vw;
    margin-bottom: 10vh;
}

.btn-back {
    background: var(--black);
    border-radius: 25px;
    font-size: 0.8em;
    padding: 5px;
    padding-left: 9px;
    padding-right: 9px;
    margin-right: 0.5rem;
}

a.btn-back {
    text-decoration: none;
    color: var(--extra-extra-light-grey);
    transition: all 0.15s linear;
}

.btn-back:hover {
    background: var(--dark-grey);
}

.comments-header {
    margin-top: 3rem;
}

.comments-area {
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 1rem;
    overflow: auto;
    width: -webkit-max-content;
    width: max-content;
    background-color: var(--black);
    color: var(--extra-extra-light-grey);
}

.comments-area .comment-content {
    font-size: 1.3rem;
}

.comments-area .comment-user {
    font-weight: bold;
}

.comments-area .comment-user,
.comments-area .comment-time {
    font-size: 0.8em;
}

.comment-votes {
    color: var(--extra-extra-light-grey);
    font-size: 0.8em;
}

.comment-votes .comment-footer-fa {
    margin-left: 3px;
}

.comment-votes .comment-footer-fa:last-child {
    margin-left: 6px;
}

.comments-container .link-embed-container {
    background: var(--extra-light-grey);
}

.comments-container .link-embed-container:hover {
    background: var(--light-grey);
}

.comments-container .link-embed {
    color: var(--black);
}

.new-comment-container {
    background: var(--extra-light-grey);
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
    padding: 18px;
    border-radius: 15px;
    margin-bottom: 2rem;
    overflow: auto;
}

.new-comment-hint {
    font-weight: 500;
}

.comment-input {
    box-sizing: border-box;
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    width: calc(100% - 75px - 20px);
    background: var(--extra-extra-light-grey);
    float: left;
    font-family: -apple-system, BlinkMacSystemFont, Inter, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}

.comment-input:focus {
    outline: 2px solid var(--dark-grey);
    font-weight: normal;
}

.comment-form .comment-btn-submit {
    background: var(--comment-submit);
    border: none;
    padding: 10px;
    border-radius: 10px;
    color: var(--extra-extra-light-grey);
    font-family: -apple-system, BlinkMacSystemFont, Inter, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    float: right;
    transform: translateY(9px);
    width: 75px;
    transition: all 0.15s linear;
}

.comment-form .comment-btn-submit:hover {
    cursor: pointer;
    background: var(--black);
}

.comment-success {
    display: none;
    margin-bottom: 2rem;
}

.comment-success.visible {
    display: block;
}

.comment-success span {
    font-weight: normal;
}
.new-post-container {
    margin-left: 20vw;
    margin-right: 20vw;
    margin-bottom: 10vh;
}

.new-post-form-container {
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 1.4rem;
    overflow: auto;
}

.new-post-form label {
    font-size: 1.2rem;
}

.new-post-form input {
    margin-bottom: 1.3rem;
}

.new-post-form .btn-submit {
    margin-top: 1.3rem;
    margin-bottom: 0;
    background: var(--accent);
    color: var(--extra-extra-light-grey);
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, Inter, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 10px;
    border-radius: 15px;
    transition: all 0.15s linear;
}

.new-post-form .btn-submit:hover {
    background: var(--black);
    cursor: pointer;
}

.error-msg {
    display: none;
    margin-bottom: 1rem;
    background: var(--error);
    padding: 10px;
    font-weight: bold;
    color: var(--extra-extra-light-grey);
    border-radius: 15px;
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
}

.error-msg.visible {
    display: block;
}

.new-hint-msg {
    margin-top: 2rem;
    padding: 20px;
    font-weight: bold;
    background: var(--accent);
    color: var(--extra-extra-light-grey);
    border-radius: 15px;
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

