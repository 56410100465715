.post-page-container {
    margin-left: 20vw;
    margin-right: 20vw;
    margin-bottom: 10vh;
}

.btn-back {
    background: var(--black);
    border-radius: 25px;
    font-size: 0.8em;
    padding: 5px;
    padding-left: 9px;
    padding-right: 9px;
    margin-right: 0.5rem;
}

a.btn-back {
    text-decoration: none;
    color: var(--extra-extra-light-grey);
    transition: all 0.15s linear;
}

.btn-back:hover {
    background: var(--dark-grey);
}

.comments-header {
    margin-top: 3rem;
}

.comments-area {
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 1rem;
    overflow: auto;
    width: max-content;
    background-color: var(--black);
    color: var(--extra-extra-light-grey);
}

.comments-area .comment-content {
    font-size: 1.3rem;
}

.comments-area .comment-user {
    font-weight: bold;
}

.comments-area .comment-user,
.comments-area .comment-time {
    font-size: 0.8em;
}

.comment-votes {
    color: var(--extra-extra-light-grey);
    font-size: 0.8em;
}

.comment-votes .comment-footer-fa {
    margin-left: 3px;
}

.comment-votes .comment-footer-fa:last-child {
    margin-left: 6px;
}

.comments-container .link-embed-container {
    background: var(--extra-light-grey);
}

.comments-container .link-embed-container:hover {
    background: var(--light-grey);
}

.comments-container .link-embed {
    color: var(--black);
}

.new-comment-container {
    background: var(--extra-light-grey);
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
    padding: 18px;
    border-radius: 15px;
    margin-bottom: 2rem;
    overflow: auto;
}

.new-comment-hint {
    font-weight: 500;
}

.comment-input {
    box-sizing: border-box;
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    width: calc(100% - 75px - 20px);
    background: var(--extra-extra-light-grey);
    float: left;
    font-family: -apple-system, BlinkMacSystemFont, Inter, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}

.comment-input:focus {
    outline: 2px solid var(--dark-grey);
    font-weight: normal;
}

.comment-form .comment-btn-submit {
    background: var(--comment-submit);
    border: none;
    padding: 10px;
    border-radius: 10px;
    color: var(--extra-extra-light-grey);
    font-family: -apple-system, BlinkMacSystemFont, Inter, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    float: right;
    transform: translateY(9px);
    width: 75px;
    transition: all 0.15s linear;
}

.comment-form .comment-btn-submit:hover {
    cursor: pointer;
    background: var(--black);
}

.comment-success {
    display: none;
    margin-bottom: 2rem;
}

.comment-success.visible {
    display: block;
}

.comment-success span {
    font-weight: normal;
}