:root {
    --accent: #1DA1F2;
    --black: #14171A;
    --dark-grey: #657786;
    --light-grey: #AAB8C2;
    --extra-light-grey: #E1E8ED;
    --extra-extra-light-grey: #F5F8FA;
    --link-embed-bg: #3e3e3e;
    --link-embed-hover: #565656;
    --error: #f21d37;
    --comment-submit: #7b35e4;
    --font-family: -apple-system, BlinkMacSystemFont, Inter, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.posts-container {
    margin-left: 20vw;
    margin-right: 20vw;
}

.posts-subheader h3 {
    font-weight: normal;
    margin: 0;
}

.posts-hint {
    margin: 0;
    margin-bottom: 1rem;
}

.posts-hint span {
    font-size: 0.85em;
}

.posts-hint span .tip-icon {
    color: var(--accent);
}

.horizontal-sep {
    border: 1px solid var(--extra-light-grey);
    margin-bottom: 2rem;
}

.post-container {
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 16px;
    overflow: auto;
}

.post-username {
    font-weight: bold;
    color: var(--dark-grey);
}

.post-date {
    color: var(--dark-grey);
}

.sep {
    color: var(--light-grey);
}

h2 .post-link {
    text-decoration: none;
    color: var(--accent);
    font-weight: 900;
    transition: color 0.15s linear;
}

.view-full {
    text-decoration: underline;
    color: var(--dark-grey);
    transition: color 0.15s linear;
}

.view-full:hover {
    color: var(--black);
}

.post-link:hover {
    color: var(--dark-grey);
}

.post-container .alignleft {
    float: left;
}

.post-container .alignright {
    float: right;
}

.post-footer {
    clear: both;
}

.post-footer-fa {
    margin-left: 10px;
}

.reactions {
    margin-top: 5px;
    margin-right: 1vw;
    background-color: #fff;
    padding: 10px;
    border-radius: 25px;
    box-shadow: 1px 3px 14px rgba(0,0,0,0.29) inset;
    color: var(--dark-grey);
}

.reactions a {
    text-decoration: none;
    transition: all 0.15s linear;
}

.reactions a:hover {
    filter: brightness(122%);
}

.comments {
    margin-right: 10px;
}

.link-embed-container {
    padding: 10px;
    background: var(--black);
    width: max-content;
    border-radius: 15px;
    transition: all 0.15s linear;
}

.link-embed-container:hover {
    background: var(--link-embed-bg);
    cursor: pointer;
}

.link-embed {
    text-decoration: none;
    color: var(--extra-extra-light-grey);
    font-size: 0.9em;
    font-weight: bold;
}

.link-url {
    transition: all 0.15s linear;
}

.link-url:hover {
    color: var(--accent);
    text-decoration: underline;
}

.link-hint {
    font-size: 0.8em;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.3ch;
}

.btn-reaction {
    border: none;
    background: transparent;
    font-size: 1rem;
    padding: 0;
    transition: all 0.15s linear;
}

.btn-reaction:hover {
    filter: brightness(122%);
    cursor: pointer;
}
