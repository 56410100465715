.new-post-container {
    margin-left: 20vw;
    margin-right: 20vw;
    margin-bottom: 10vh;
}

.new-post-form-container {
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 1.4rem;
    overflow: auto;
}

.new-post-form label {
    font-size: 1.2rem;
}

.new-post-form input {
    margin-bottom: 1.3rem;
}

.new-post-form .btn-submit {
    margin-top: 1.3rem;
    margin-bottom: 0;
    background: var(--accent);
    color: var(--extra-extra-light-grey);
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, Inter, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 10px;
    border-radius: 15px;
    transition: all 0.15s linear;
}

.new-post-form .btn-submit:hover {
    background: var(--black);
    cursor: pointer;
}

.error-msg {
    display: none;
    margin-bottom: 1rem;
    background: var(--error);
    padding: 10px;
    font-weight: bold;
    color: var(--extra-extra-light-grey);
    border-radius: 15px;
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
}

.error-msg.visible {
    display: block;
}

.new-hint-msg {
    margin-top: 2rem;
    padding: 20px;
    font-weight: bold;
    background: var(--accent);
    color: var(--extra-extra-light-grey);
    border-radius: 15px;
    box-shadow: 3px 10px 14px rgba(0, 0, 0, 0.15);
}