@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Inter, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    letter-spacing: -0.2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--extra-extra-light-grey);
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.posts-header {
    overflow: auto;
}

.posts-header h1 {
    float: left;
}

.btn-new-post {
    float: right;
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 18px;
    background: var(--accent);
    padding: 0.8rem;
    border-radius: 15px;
}

a.btn-new-post {
    text-decoration: none;
    color: var(--extra-extra-light-grey);
    font-weight: bold;
    transition: all 0.15s linear;
}

.btn-new-post:hover {
    background: var(--black);
}
